import { EventMessage, EventType, InteractionType, IPublicClientApplication, PublicClientApplication } from '@azure/msal-browser';
import { MsalAuthenticationTemplate, MsalProvider } from '@azure/msal-react';
import { useEffect, useState } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { Permission } from './models/permission';
import { CvxSiteNavigation, MenuItemDefinition, NavigationService } from './modules/cvx-ui-module';
import { OdsAvoVocApiService, OdsFormApiService, OdsLdarApiService, OdsOrdApiService, OdsPersonnelApiService, OdsUserApiService } from './services';
import { ApiService } from './services/api-service';
import { OdsApiService } from './services/ods-api-service';
import OdsService from './services/ods-service';
import { OdsAvoVocCompletedInspectionRequestsView } from './views/avo-voc-inspections/dashboard/avo-voc-completed-inspections.view';
import { OdsAvoVocFacilityInspectionFrequencyView } from './views/avo-voc-inspections/frequencies/avo-voc-inspection-frequencies.view';
import { OdsAvoVocInspectionRequestsView } from './views/avo-voc-inspections/dashboard/avo-voc-inspection-requests.view';
import { OdsAvoVocUndispatchedInspectionRequestsView } from './views/avo-voc-inspections/dashboard/avo-voc-undispatched-inspections.view';
import OdsBradenheadDashboardView from './views/bradenhead/dashboard/bradenhead-dashboard.view';
import OdsBradenheadDispatchesView from './views/bradenhead/dispatches/bradenhead-dispatches.view';
import { OdsFormDataSourcesView } from './views/forms/form-data-sources/form-data-sources.view';
import OdsFormImageView from './views/forms/form-image/form-image.view';
import OdsFormManagementDetailView from './views/forms/form-management/form-management-detail.view';
import OdsFormManagementView from './views/forms/form-management/form-management.view';
import OdsFormSubmissionsView from './views/forms/form-submissions/form-submissions.view';
import Home from './views/home/home';
import { OdsLdarCompletedInspectionsView } from './views/ldar/dashboard/ldar-completed-inspections.view';
import { OdsLdarDashboardView } from './views/ldar/dashboard/ldar-dashboard.view';
import { OdsLdarDispatchedInspectionsView } from './views/ldar/dashboard/ldar-dispatched-inspections.view';
import { OdsLdarScheduledInspectionsView } from './views/ldar/dashboard/ldar-scheduled-inspections.view';
import { OdsLdarInspectionRequestDispatchesView } from './views/ldar/dispatches/ldar-dispatches.view';
import { OdsLdarHolidaysView } from './views/ldar/holidays/ldar-holidays.view';
import { OdsRolesView } from './views/permissions/roles/permissions-roles.view';
import { OdsUsersView } from './views/permissions/users/permissions-users.view';
import { OdsPersonnelGroupTypesView } from './views/personnel/group-types/personnel-group-types.view';
import { OdsPersonnelGroupsView } from './views/personnel/groups/personnel-groups.view';
import { OdsPersonnelView } from './views/personnel/personnel/personnel-personnel.view';
import { OdsRoutesView } from './views/personnel/routes/personnel-routes.view';
import OdsReg7HaulerDashboardView from './views/reg7-hauler/dashboard/reg7-hauler-dashboard.view';
import { OdsOrdCompletedInspectionRequestsView } from './views/ord/dashboard/ord-completed-dashboard';
import { OdsOrdInspectionRequestsView } from './views/ord/dashboard/ord-dashboard.view';
import { OdsOrdOnHoldInspectionRequestsView } from './views/ord/dashboard/ord-onhold-dashboard';
import { OdsOrdUndispatchedInspectionRequestsView } from './views/ord/dashboard/ord-undispatched.view';
import { OdsOrdDispatchedInspectionRequestsView } from './views/ord/dashboard/ord-dispatched.view';
import { OdsOrdInspectionRequestDispatchesView } from './views/ord/dispatches/ord-dispatches.view';

function App() {
  const [pca, setPca] = useState<IPublicClientApplication | undefined>(undefined);
  const [odsService, setOdsService] = useState<OdsService | undefined>(undefined);
  const [odsApiService, setOdsApiService] = useState<OdsApiService>(new OdsApiService(undefined));

  const [userService, setUserService] = useState<OdsUserApiService>(new OdsUserApiService(undefined));
  const [avoVocService, setAvoVocService] = useState<OdsAvoVocApiService>(new OdsAvoVocApiService(undefined));
  const [ordService, setOrdService] = useState<OdsOrdApiService>(new OdsOrdApiService(undefined));
  const [ldarService, setLdarService] = useState<OdsLdarApiService>(new OdsLdarApiService(undefined));
  const [personnelService, setPersonnelService] = useState<OdsPersonnelApiService>(new OdsPersonnelApiService(undefined));
  const [formService, setFormService] = useState<OdsFormApiService>(new OdsFormApiService(undefined));

  const navigationService = NavigationService.configure({
    siteMenu: [
      {
        label: 'Help', to: '/help/request-access', menuItems: [
        ]
      }
    ]
  });

  useEffect(() => {
    async function fetchData() {
      const response = await fetch('/api/v1.0/settings', { method: 'POST' });
      const settings = await response.json();

      const config = {
        auth: {
          clientId: settings.clientId,
          authority: `https://login.microsoftonline.com/${settings.tenantId}`,
          redirectUri: "/",
          postLogoutRedirectUri: "/",
        },
      };

      const pca = new PublicClientApplication(config);
      setPca(pca);

      const apiService = new ApiService(pca, settings.scopes);
      
      setOdsApiService(new OdsApiService(apiService));
      setUserService(new OdsUserApiService(apiService));
      setPersonnelService(new OdsPersonnelApiService(apiService));
      setLdarService(new OdsLdarApiService(apiService));
      setOrdService(new OdsOrdApiService(apiService))
      setAvoVocService(new OdsAvoVocApiService(apiService));
      setFormService(new OdsFormApiService(apiService));

      const service = new OdsService(pca, settings.scopes);
      setOdsService(service);
    };

    fetchData();
  }, []);

  useEffect(() => {
    async function fetchPermissions() {
      if (pca && pca.getAllAccounts()[0] && odsService) {
        const permissions = await userService.RetrieveUserPermissionsAsync();

        const siteMenu: MenuItemDefinition[] = [];

        if (permissions.find(p => p === Permission.ViewBradenheadInspectionRequests)) {
          siteMenu.push({
            label: 'Monthly Bradenhead Pressure', to: '/bradenhead/dashboard', menuItems: [
              { label: 'Dashboard', to: '/bradenhead/dashboard' },
              { label: 'Dispatches', to: '/bradenhead/dispatches' },
            ]
          });
        }

        if (permissions.find(p => p === Permission.Administrator || p === Permission.EditFormSubmissions || p === Permission.EditFormDataSources)) {
          const formsMenuItem: MenuItemDefinition = new MenuItemDefinition;
          formsMenuItem.label = "Forms";
          formsMenuItem.menuItems = [];

          if (permissions.find(p => p === Permission.Administrator)) {
            formsMenuItem.href = '/forms/management';
            formsMenuItem.menuItems = [...formsMenuItem.menuItems, { label: 'Management', to: '/forms/management' }]
          }

          if (permissions.find(p => p === Permission.Administrator)) {
            formsMenuItem.href = '/forms/dispatches';
            formsMenuItem.menuItems = [...formsMenuItem.menuItems, { label: 'Dispatches', to: '/forms/dispatches' }]
          }

          if (permissions.find(p => p === Permission.Administrator || p === Permission.EditFormSubmissions)) {
            formsMenuItem.href = '/forms/submissions';
            formsMenuItem.menuItems = [...formsMenuItem.menuItems, { label: 'Submissions', to: '/forms/submissions' }]
          }

          if (permissions.find(p => p === Permission.Administrator || p === Permission.EditFormDataSources)) {
            formsMenuItem.href = '/forms/formDataSources';
            formsMenuItem.menuItems = [...formsMenuItem.menuItems, { label: 'Form Data Sources', to: '/forms/formDataSources' }]
          }

          siteMenu.push(formsMenuItem);
        }

        if (permissions.find(p => p === Permission.ViewLDARInspectionRequests)) {
          siteMenu.push({
            label: 'LDAR', to: '/ldar/dashboard', menuItems: [
              { label: 'Dashboard', to: '/ldar/dashboard' },
              { label: 'Scheduled Inspections', to: '/ldar/scheduled' },
              { label: 'Dispatched Inspections', to: '/ldar/dispatched' },
              { label: 'Completed Inspections', to: '/ldar/completed' },
              { label: 'Dispatches', to: '/ldar/dispatches' },
              { label: 'Holidays', to: '/ldar/holidays' },
              // { label: 'Inspection Statuses', to: '/ldar/inspection-statuses' },
              // { label: 'Inspection Types', to: '/ldar/inspection-types' },
            ]
          });
        }

        if (permissions.find(p => p === Permission.ViewORDInspectionRequests)) {
          siteMenu.push({
            label: 'ORD', to: '/ord/dashboard', menuItems: [
              { label: 'Dashboard', to: '/ord/dashboard' },
              { label: 'Undispatched Inspections', to: '/ord/undispatched' },
              { label: 'Dispatched Inspections', to: '/ord/dispatched' },
              { label: 'On-Hold Inspections', to: '/ord/onhold' },
              { label: 'Completed Inspections', to: '/ord/completed' },
              { label: 'Dispatches', to: '/ord/dispatches' },
            ]
          });
        }

        if (permissions.find(p => p === Permission.Administrator)) {
          siteMenu.push({
            label: 'Reg 7 Hauler Inspections', to: '/reg7/dashboard', menuItems: [
              { label: 'Dashboard', to: '/reg7/dashboard' },
            ]
          });
        }

        if (permissions.find(p => p === Permission.ViewAvoVocInspectionRequests)) {
          siteMenu.push({
            label: 'AVO/VOC Inspections', to: '/avoVocInspections/dashboard', menuItems: [
              { label: 'Dashboard', to: '/avoVocInspections/dashboard' },
              { label: 'Undispatched', to: '/avoVocInspections/undispatched' },
              { label: 'Completed', to: '/avoVocInspections/completed' },
              { label: 'Frequencies', to: '/avoVocInspections/frequencies' },
            ]
          });
        }

        if (permissions.find(p => p === Permission.ViewPersonnel)) {
          siteMenu.push({
            label: 'Personnel', to: '/personnel', menuItems: [
              { label: 'Personnel', to: '/personnel' },
              { label: 'Groups', to: '/personnel/groups' },
              { label: 'Routes', to: '/personnel/routes' },
              { label: 'Personnel Group Types', to: '/personnel/groupTypes' },
            ]
          });
        }

        if (permissions.find(p => p === Permission.ViewPermissions)) {
          siteMenu.push({
            label: 'Permissions', to: '/permissions/users', menuItems: [
              { label: 'Users', to: '/permissions/users' },
              { label: 'Roles', to: '/permissions/roles' },
            ]
          });
        }

        navigationService.setSiteMenu(siteMenu);
      }
    }

    const callbackId = pca?.addEventCallback((message: EventMessage) => {
      if (message.eventType === EventType.LOGIN_SUCCESS) {
        fetchPermissions();
      }
    });

    fetchPermissions();

    return () => {
      if (callbackId) {
        pca?.removeEventCallback(callbackId);
      }
    }
  }, [pca, odsService]);

  return pca !== undefined ? (
    <MsalProvider instance={pca}>
      <div className='cvx-app theme-blue'>
        <MsalAuthenticationTemplate interactionType={InteractionType.Redirect}>
          <Router>
            <CvxSiteNavigation navigationService={navigationService}></CvxSiteNavigation>
            <div className='cvx-content'>
              <Routes>
                <Route path="/" element={<Home />} />
                <Route path="/bradenhead/dashboard" element={<OdsBradenheadDashboardView service={odsApiService} odsService={odsService} personnelService={personnelService} />} />
                <Route path="/bradenhead/dispatches" element={<OdsBradenheadDispatchesView odsService={odsService} />} />
                
                <Route path="/forms/submissions" element={<OdsFormSubmissionsView odsService={odsService} />} />
                <Route path="/forms/dispatches" element={<OdsFormSubmissionsView odsService={odsService} />} />
                <Route path="/forms/management/:formId" element={<OdsFormManagementDetailView odsService={odsService} />} />
                <Route path="/forms/management" element={<OdsFormManagementView odsService={odsService} />} />
                <Route path="/forms/images/:imageId" element={<OdsFormImageView odsService={odsService} />} />
                <Route path="/forms/formDataSources" element={<OdsFormDataSourcesView service={formService} />} />

                <Route path="/ldar/dashboard" element={<OdsLdarDashboardView service={ldarService} personnelService={personnelService} />} />
                <Route path="/ldar/scheduled" element={<OdsLdarScheduledInspectionsView service={ldarService} personnelService={personnelService} />} />
                <Route path="/ldar/dispatched" element={<OdsLdarDispatchedInspectionsView service={ldarService} personnelService={personnelService} />} />
                <Route path="/ldar/completed" element={<OdsLdarCompletedInspectionsView service={ldarService} personnelService={personnelService} />} />
                <Route path="/ldar/dispatches" element={<OdsLdarInspectionRequestDispatchesView service={ldarService} personnelService={personnelService} />} />
                <Route path="/ldar/holidays" element={<OdsLdarHolidaysView service={ldarService} />} />
                
                <Route path="/ord/dashboard" element={<OdsOrdInspectionRequestsView service={ordService} personnelService={personnelService} />} />
                <Route path="/ord/dispatched" element={<OdsOrdDispatchedInspectionRequestsView service={ordService} personnelService={personnelService} />} />
                <Route path="/ord/undispatched" element={<OdsOrdUndispatchedInspectionRequestsView service={ordService} personnelService={personnelService} />} />
                <Route path="/ord/onhold" element={<OdsOrdOnHoldInspectionRequestsView service={ordService} personnelService={personnelService} />} />
                <Route path="/ord/completed" element={<OdsOrdCompletedInspectionRequestsView service={ordService} personnelService={personnelService} />} />
                <Route path="/ord/dispatches" element={<OdsOrdInspectionRequestDispatchesView service={ordService} personnelService={personnelService} />} />
                
                <Route path="/reg7/dashboard" element={<OdsReg7HaulerDashboardView odsService={odsService} />} />
                
                <Route path="/avoVocInspections/frequencies" element={<OdsAvoVocFacilityInspectionFrequencyView service={avoVocService} personnelService={personnelService} />} />
                <Route path="/avoVocInspections/dashboard" element={<OdsAvoVocInspectionRequestsView service={avoVocService} personnelService={personnelService} />} />
                <Route path="/avoVocInspections/undispatched" element={<OdsAvoVocUndispatchedInspectionRequestsView service={avoVocService} personnelService={personnelService} />} />
                <Route path="/avoVocInspections/completed" element={<OdsAvoVocCompletedInspectionRequestsView service={avoVocService} personnelService={personnelService} />} />
                
                <Route path="/personnel" element={<OdsPersonnelView service={personnelService} />} />
                <Route path="/personnel/groups" element={<OdsPersonnelGroupsView service={personnelService} />} />
                <Route path="/personnel/routes" element={<OdsRoutesView service={personnelService} />} />
                <Route path="/personnel/groupTypes" element={<OdsPersonnelGroupTypesView service={personnelService} />} />
                
                <Route path="/permissions/users" element={<OdsUsersView service={userService} />} />
                <Route path="/permissions/roles" element={<OdsRolesView service={userService} />} />
              </Routes>
            </div>
          </Router>
        </MsalAuthenticationTemplate>
      </div>
    </MsalProvider>
  ) : (
    <div>Loading</div>
  );
}

export default App;
