import * as yup from 'yup';
import { OdsAsyncSelect } from '../../components';
import OdsInput from "../../components/form-editors/input/input";
import OdsSelect from '../../components/form-editors/select/select';
import { StringFilter } from '../../models';
import { AvoVocFacilityInspectionFrequency } from '../../models/avo-voc-dashboard-frequency';
import { AvoVocInspectionFrequency } from '../../models/avo-voc-inspection-frequency';
import { FacilityFilter } from '../../models/facility-filter';
import { OdsAvoVocApiService } from '../../services';
import OdsBaseAddEditDialog from "../base-add-edit-dialog/base-add-edit.dialog";

export interface OdsEditAvoVocFrequencyDialogProps {
    service: OdsAvoVocApiService;
    show: boolean;
    frequency: AvoVocFacilityInspectionFrequency | undefined;
    inspectionFrequencies: AvoVocInspectionFrequency[];
    onComplete: (dialogResult: boolean, user: AvoVocFacilityInspectionFrequency | undefined) => Promise<any>;
}

export const OdsEditAvoVocFrequencyDialog: React.FC<OdsEditAvoVocFrequencyDialogProps> = ({
    service,
    show,
    frequency,
    inspectionFrequencies,
    onComplete,
    ...props
}) => {
    const schema = yup.object().shape({
        avoInspectionFrequency: yup.string().required(),
        vocInspectionFrequency: yup.string().required(),
        facility: yup.object().nullable().required()
    });

    const onSearchAvailableFacilities = async (top: number, skip: number, filterStr: string) => {
        const stringFilter = new StringFilter();
        const filter = new FacilityFilter();

        filter.name = stringFilter;
        filter.name.like = filterStr;

        let data = await service.RetrieveAvoVocFacilityInspectionFrequencyAvailableFacilityValuesAsync(top, skip, 'Name', 'Asc', filter);
        return data;
    };

    return (
        <OdsBaseAddEditDialog
            show={show}
            title={frequency?.id ? 'Edit Facility Inspection Frequency: ' + frequency.facility?.name : 'Add Facility Inspection Frequency'}
            validationSchema={schema}
            initialValue={frequency ?? new AvoVocFacilityInspectionFrequency()}
            onComplete={onComplete}
        >
            {!frequency?.id
                ? <OdsAsyncSelect name="facility" label="Facility" labelKey="name" onSearch={onSearchAvailableFacilities} />
                : <OdsInput disabled={true} name="facility.name" label='Facility' />}
            <OdsSelect name="avoInspectionFrequency" label='AVO Inspection Frequency' items={inspectionFrequencies} />
            <OdsSelect value="vocInspectionFrequency" name="vocInspectionFrequency" label='VOC Inspection Frequency' items={inspectionFrequencies} />
        </OdsBaseAddEditDialog>
    );
};
