import React, { useEffect, useState } from "react";
import { Spinner } from "react-bootstrap";
import { Facility, Route } from "../../models";
import { OdsPersonnelApiService } from "../../services";

interface OdsFacilityInspectionRouteColumnPairProps {
    item: Item;
    personnelService: OdsPersonnelApiService;
}

interface Item {
    facilityId?: number;
    facility?: Facility;
}

export const OdsFacilityInspectionRouteColumnPair: React.FC<OdsFacilityInspectionRouteColumnPairProps> = ({
    item,
    personnelService
}) => {
    const [isLoadingFacility, setIsLoadingFacility] = useState<boolean>(true);
    const [facility, setFacility] = useState<Facility>();

    const [isLoadingRoute, setIsLoadingRoute] = useState<boolean>(true);
    const [route, setRoute] = useState<Route>();

    const onFetchRoute = (id: number, personnelService: OdsPersonnelApiService) => personnelService.RetrieveRouteAsync(id);

    const onFetchFacility = async (item: Item, personnelService: OdsPersonnelApiService) => {
        if (personnelService && item && item.facilityId) {
            setIsLoadingFacility(true);
            setIsLoadingRoute(true);

            const facility = await personnelService.RetrieveFacilityAsync(item.facilityId);

            item.facility = facility;
            setFacility(facility);
            setIsLoadingFacility(false);

            if (item.facility && item.facility.inspectionRouteId) {
                const route = await onFetchRoute(item.facility.inspectionRouteId, personnelService);

                item.facility.inspectionRoute = route;
                setRoute(route);
                setIsLoadingRoute(false);
            }
        }
    }

    useEffect(() => {
        onFetchFacility(item, personnelService);
    }, [item, personnelService]);

    return (
        <>
            <td>
                <span className="ods-item-chip">{isLoadingFacility === false ? facility?.name : <Spinner animation="border" role="status" variant="primary" style={{ width: '16px', height: '16px' }}>
                    <span className="visually-hidden">Loading...</span>
                </Spinner>}</span>
            </td>
            <td>
                <span className="ods-item-chip">{isLoadingRoute === false ? route?.name : <Spinner animation="border" role="status" variant="primary" style={{ width: '16px', height: '16px' }}>
                    <span className="visually-hidden">Loading...</span>
                </Spinner>}</span>
            </td>
        </>
    );
}
