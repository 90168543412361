import React, { useEffect, useState } from "react";
import { Spinner } from "react-bootstrap";
import { Facility } from "../../models/facility";

export interface OdsFacilityChipProps {
    id?: number,
    onFetchFacility: (id: number) => Promise<Facility>;
}

export const OdsFacilityChip: React.FC<OdsFacilityChipProps> = ({
    id,
    onFetchFacility
}) => {
    const [data, setData] = useState<Facility>();
    const [isLoading, setIsLoading] = useState<boolean>(true);

    const fetchData = async () => {
        setIsLoading(true);
        if (id) {
            const data = await onFetchFacility(id);
            setData(data);
        }
        setIsLoading(false);
    }

    useEffect(() => {
        fetchData();
    }, [id])

    return (
        <span className="ods-item-chip">{isLoading === false ? data?.name : <Spinner animation="border" role="status" variant="primary" style={{ width: '16px', height: '16px' }}>
            <span className="visually-hidden">Loading...</span>
        </Spinner>}</span>
    )
}
