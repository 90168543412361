import { useReducer, useState } from "react";
import { OdsDateColumn, OdsDateColumnHeader, OdsDateTimeColumn, OdsDispatchPersonnelColumn, OdsFacilityChip, OdsFacilityInspectionRouteColumnPair, OdsIntegerColumn, OdsIntegerColumnHeader, OdsItemColumnHeader, OdsPersonnelChip, OdsRouteChip, OdsSelectableTableRow, OdsSelectItemColumn, OdsStringColumnHeader, OdsTable, OdsTableBanner } from "../../../components";
import { OdsAvoVocDispatchDialog } from "../../../dialogs";
import { useMyself, usePagedListResult } from "../../../hooks";
import { AvoVocInspectionRequest, AvoVocInspectionRequestFilter, DateFilter, IntegerFilter, ItemFilter, Permission, StringFilter } from "../../../models";
import { CvxContentBlock, CvxLayoutCenterColumn, CvxPage, CvxTableContentBlock } from "../../../modules/cvx-ui-module";
import { OdsAvoVocApiService, OdsPersonnelApiService, ViewHelper } from "../../../services";

export interface OdsAvoVocInspectionRequestsViewProps {
    service: OdsAvoVocApiService;
    personnelService: OdsPersonnelApiService;
}

export const OdsAvoVocInspectionRequestsView: React.FC<OdsAvoVocInspectionRequestsViewProps> = ({
    service,
    personnelService,
}) => {
    const [canEdit, showState, states] = useMyself(service, Permission.EditAvoVocInspectionRequests);

    const onFetchData = (top: number, skip: number, sort: string, sortDirection: string, filter: AvoVocInspectionRequestFilter) => {
        return service.RetrieveActiveAvoVocInspectionRequestListAsync(top, skip, sort, sortDirection, filter);
    };

    const [data, selectedItems, pageIndex, pageSize, sort, sortDirection, filter, isLoading,
        onRefresh, onPageChanged, onSortChanged, onClearFilter, onRowSelect]
        = usePagedListResult('DueDate', 'Asc', new AvoVocInspectionRequestFilter(), onFetchData);

    const [showDispatchDialog, setShowDispatchDialog] = useState<boolean>(false);

    
    const onFetchPersonnel = (item: any, id: number) => personnelService.RetrievePersonnelAsync(id);


    const onDispatch = () => setShowDispatchDialog(true);

    const onDispatchComplete = () => { setShowDispatchDialog(false); onRefresh() };

    const onDispatchItem = async (item: AvoVocInspectionRequest, users: string[]) => {
        await service.DispatchAvoVocInspectionRequestAsync(item, users);
    };


    const onExport = async () => {
        const blob = await service.ExportAvoVocInspectionRequestListAsync(sort, sortDirection, filter);
        ViewHelper.exportData(`avo-voc-inspection-requests-${(new Date()).toLocaleDateString()}.csv`, blob)
    }


    const onFilterId = (newFilter?: IntegerFilter) => { filter.id = newFilter; onRefresh(); }
    const onFilterInspectionType = (newFilter?: StringFilter) => { filter.inspectionType = newFilter; onRefresh(); }
    const onFilterFrequency = (newFilter?: ItemFilter) => { filter.frequency = newFilter; onRefresh(); }
    const onFilterFacilityName = (newFilter?: StringFilter) => { filter.facilityName = newFilter; onRefresh(); }
    const onFilterRouteName = (newFilter?: StringFilter) => { filter.routeName = newFilter; onRefresh(); }
    const onFilterStartDate = (newFilter?: DateFilter) => { filter.startDate = newFilter; onRefresh(); }
    const onFilterDispatchDate = (newFilter?: DateFilter) => { filter.dispatchDate = newFilter; onRefresh(); }
    const onFilterDispatchUser = (newFilter?: ItemFilter) => { filter.dispatchUser = newFilter; onRefresh(); };
    const onFilterDueDate = (newFilter?: DateFilter) => { filter.dueDate = newFilter; onRefresh(); }
    const onFilterInspectionDate = (newFilter?: DateFilter) => { filter.inspectionDate = newFilter; onRefresh(); }

    const onFilterValueSearch = async (columnName: string, top: number, skip: number, filter: string) => service.RetrieveAvoVocInspectionRequestSearchListAsync(columnName, top, skip, filter);


    return (
        <>
        <OdsAvoVocDispatchDialog service={personnelService} show={showDispatchDialog} onComplete={onDispatchComplete} inspectionRequests={selectedItems} onDispatchItem={onDispatchItem} />
            <CvxPage desktop={
                <CvxLayoutCenterColumn>
                    <CvxContentBlock>
                        <OdsTableBanner onClearFilter={onClearFilter} onRefresh={onRefresh} onDispatch={canEdit ? onDispatch : undefined} onExport={onExport} />
                    </CvxContentBlock>
                    <CvxTableContentBlock>
                        <OdsTable heading="AVO/VOC Inspections" data={data} isLoading={isLoading} pageIndex={pageIndex} pageSize={pageSize} onPageChanged={onPageChanged}
                            tableHeader={
                                <tr>
                                    <th></th>
                                    <OdsIntegerColumnHeader name="Id" header="Id" filter={filter.id} sort={sort} sortDirection={sortDirection}
                                        onSortChanged={onSortChanged} onFilterChanged={onFilterId} onSearch={onFilterValueSearch} />
                                    <OdsStringColumnHeader name="InspectionType" header="Type" filter={filter.inspectionType} sort={sort} sortDirection={sortDirection}
                                        onSortChanged={onSortChanged} onFilterChanged={onFilterInspectionType} onSearch={onFilterValueSearch} />
                                    <OdsItemColumnHeader name="Frequency" header="Frequency" filter={filter.frequency} sort={sort} sortDirection={sortDirection}
                                        onSortChanged={onSortChanged} onFilterChanged={onFilterFrequency} onSearch={onFilterValueSearch} />
                                    <OdsStringColumnHeader name="FacilityName" header="Facility" filter={filter.facilityName} sort={sort} sortDirection={sortDirection}
                                        onSortChanged={onSortChanged} onFilterChanged={onFilterFacilityName} onSearch={onFilterValueSearch} />
                                    <OdsStringColumnHeader name="RouteName" header="Route" filter={filter.routeName} sort={sort} sortDirection={sortDirection}
                                        onSortChanged={onSortChanged} onFilterChanged={onFilterRouteName} onSearch={onFilterValueSearch} />
                                    <OdsDateColumnHeader name="StartDate" header="Start&nbsp;Date" filter={filter.startDate} sort={sort} sortDirection={sortDirection}
                                        onSortChanged={onSortChanged} onFilterChanged={onFilterStartDate} onSearch={onFilterValueSearch} />
                                    <OdsDateColumnHeader name="DueDate" header="Due&nbsp;Date" filter={filter.dueDate} sort={sort} sortDirection={sortDirection}
                                        onSortChanged={onSortChanged} onFilterChanged={onFilterDueDate} onSearch={onFilterValueSearch} />
                                    <OdsDateColumnHeader name="DispatchDate" header="Dispatch&nbsp;Date" filter={filter.dispatchDate} sort={sort} sortDirection={sortDirection}
                                        onSortChanged={onSortChanged} onFilterChanged={onFilterDispatchDate} onSearch={onFilterValueSearch} />
                                    <OdsItemColumnHeader name="DispatchUser" header="Dispatch Users" filter={filter.dispatchUser}
                                        onFilterChanged={onFilterDispatchUser} onSearch={onFilterValueSearch} />
                                </tr>
                            }
                            tableRow={(item) =>
                                <OdsSelectableTableRow item={item} selectedItems={selectedItems}>
                                    <OdsSelectItemColumn item={item} onSelectItem={onRowSelect} />
                                    <OdsIntegerColumn>{item.id}</OdsIntegerColumn>
                                    <td className="center-column">{item.inspectionType}</td>
                                    <td className="center-column">{item.frequency}</td>
                                    <OdsFacilityInspectionRouteColumnPair item={item} personnelService={personnelService} />
                                    <OdsDateColumn value={item.startDate} />
                                    <OdsDateColumn value={item.dueDate} />
                                    <OdsDateTimeColumn value={item.dispatchDate} />
                                    <OdsDispatchPersonnelColumn item={item} personnelService={personnelService} />
                                </OdsSelectableTableRow>
                            }
                        />
                    </CvxTableContentBlock>
                </CvxLayoutCenterColumn>
            } />
        </>
    );
};
