import { useEffect, useState } from "react";
import { Badge, Spinner } from "react-bootstrap";
import { Personnel } from "../../models";
import { OdsPersonnelApiService } from "../../services";
import { OdsPersonnelChip } from "../personnel-chip/personnel-chip";

interface OdsDispatchPersonnelColumnProps {
    item: Item;
    personnelService: OdsPersonnelApiService
}

interface Item {
    dispatchUserIds: number[];
}

export const OdsDispatchPersonnelColumn: React.FC<OdsDispatchPersonnelColumnProps> = ({
    item,
    personnelService
}) => {

    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [personnel, setPersonnel] = useState<Personnel[]>([]);
    const [personnelList, setPersonnelList] = useState<Personnel[]>([]);


    const onFetchPersonnel = async (item: Item, personnelService: OdsPersonnelApiService) => {
        setPersonnel([]);
        setPersonnelList([]);
        setIsLoading(true);

        let personnelList = [];

        for (const personnelId of item?.dispatchUserIds) {
            const personnel = await personnelService.RetrievePersonnelAsync(personnelId);
            personnelList.push(personnel);
        };

        setPersonnelList(personnelList);
        setPersonnel(personnelList.slice(0, 3));
        setIsLoading(false);
    }


    useEffect(() => {
        onFetchPersonnel(item, personnelService);
    }, [item, personnelService]);

    return (
        <td>
            <span className="ods-item-chip">{(isLoading === false ?
                personnel.map(d => <Badge pill bg="secondary">{`${d.firstName} ${d.lastName}`}</Badge>)
                : <Spinner animation="border" role="status" variant="primary" style={{ width: '16px', height: '16px' }}>
                    <span className="visually-hidden">Loading...</span>
                </Spinner>)}</span>
        </td>
    )
};
