import { BooleanFilter } from "./boolean-filter";
import { DateFilter } from "./date-filter";
import { IntegerFilter } from "./integer-filter";
import { ItemFilter } from "./item-filter";
import { StringFilter } from "./string-filter";

export class AvoVocInspectionRequestFilter {
    public id?: IntegerFilter;
    public inspectionType?: StringFilter;
    public frequency?: ItemFilter;
    public facilityName?: StringFilter;
    public routeName?: StringFilter;
    public state?: ItemFilter;
    public dispatchUser?: StringFilter;
    public dispatchDate?: DateFilter;
    public dueDate?: DateFilter;
    public inspectionDate?: DateFilter;
    public startDate?: DateFilter;
    public completionType?: ItemFilter;

    public isDispatched?: BooleanFilter;
    public isCompleted?: BooleanFilter;
    public isActive?: BooleanFilter;
}
