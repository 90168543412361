import { IntegerFilter } from "./integer-filter";
import { ItemFilter } from "./item-filter";
import { StringFilter } from "./string-filter";

export class AvoVocFacilityInspectionFrequencyFilter {
    public facilityId?: IntegerFilter;
    public facilityName?: StringFilter;
    public routeId?: IntegerFilter;
    public routeName?: StringFilter;
    public inspectionRouteName?: StringFilter;
    public avoFrequency?: ItemFilter;
    public vocFrequency?: ItemFilter;
}