import { useReducer, useState } from "react";
import { BsCalendar4Week } from "react-icons/bs";
import { OdsEditItemColumn, OdsFacilityChip, OdsFacilityInspectionRouteColumnPair, OdsIntegerColumn, OdsIntegerColumnHeader, OdsItemColumnHeader, OdsRemoveItemColumn, OdsRouteChip, OdsSelectableTableRow, OdsSelectItemColumn, OdsStringColumnHeader, OdsTable, OdsTableBanner } from "../../../components";
import { OdsConfirmDeleteDialog, OdsEditAvoVocFrequencyDialog } from "../../../dialogs";
import { useMyself, usePagedListResult } from "../../../hooks";
import { AvoVocFacilityInspectionFrequency, AvoVocFacilityInspectionFrequencyFilter, IntegerFilter, ItemFilter, Permission, StringFilter } from "../../../models";
import { CvxContentBlock, CvxLayoutCenterColumn, CvxPage, CvxTableContentBlock, Themes } from "../../../modules/cvx-ui-module";
import { OdsAvoVocApiService, OdsPersonnelApiService, ViewHelper } from "../../../services";

export interface OdsAvoVocFacilityInspectionFrequencyViewProps {
    service: OdsAvoVocApiService;
    personnelService: OdsPersonnelApiService;
}

export const OdsAvoVocFacilityInspectionFrequencyView: React.FC<OdsAvoVocFacilityInspectionFrequencyViewProps> = ({
    service,
    personnelService,
}) => {
    const [canEdit, showState, states] = useMyself(service, Permission.EditAvoVocInspectionRequests);

    const onFetchData = (top: number, skip: number, sort: string, sortDirection: string, filter: AvoVocFacilityInspectionFrequencyFilter) => {
        return service.RetrieveAvoVocFacilityInspectionFrequencyListAsync(top, skip, sort, sortDirection, filter);
    };

    const [data, selectedItems, pageIndex, pageSize, sort, sortDirection, filter, isLoading,
        onRefresh, onPageChanged, onSortChanged, onClearFilter, onRowSelect]
        = usePagedListResult('FacilityName', 'Asc', new AvoVocFacilityInspectionFrequencyFilter(), onFetchData);

    const [editItem, setEditItem] = useState<AvoVocFacilityInspectionFrequency>();
    const [removeItem, setRemoveItem] = useState<AvoVocFacilityInspectionFrequency>();


    const onAddItem = () => setEditItem(new AvoVocFacilityInspectionFrequency());

    const onEditItem = (item: AvoVocFacilityInspectionFrequency) => setEditItem(item);

    const onEditItemCompleted = async (dialogResult: boolean, item?: AvoVocFacilityInspectionFrequency) => {
        if (dialogResult && item) {
            if (item.id) {
                await service.UpdateAvoVocFacilityInspectionFrequencyAsync(item);
            } else {
                await service.CreateAvoVocFacilityInspectionFrequencyAsync(item);
            }
            onRefresh();
        }
        setEditItem(undefined);
    }

    const onRemoveItem = (item: AvoVocFacilityInspectionFrequency) => setRemoveItem(item);

    const onRemoveItemCompleted = async (dialogResult: boolean) => {
        if (dialogResult && removeItem?.id) {
            await service.DeleteAvoVocFacilityInspectionFrequencyAsync(removeItem);
            onRefresh();
        }
        setRemoveItem(undefined);
    }

    const onExport = async () => {
        const blob = await service.ExportAvoVocFacilityInspectionFrequencyListAsync(sort, sortDirection, filter);
        ViewHelper.exportData(`avo-voc-inspection-frequency-${(new Date()).toLocaleDateString()}.csv`, blob)
    }


    const onFilterFacilityId = (newFilter?: IntegerFilter) => { filter.facilityId = newFilter; onRefresh(); };
    const onFilterFacilityName = (newFilter?: StringFilter) => { filter.facilityName = newFilter; onRefresh(); };
    const onFilterRouteName = (newFilter?: StringFilter) => { filter.routeName = newFilter; onRefresh(); };
    const onFilterAvoFrequency = (newFilter?: ItemFilter) => { filter.avoFrequency = newFilter; onRefresh(); };
    const onFilterVocFrequency = (newFilter?: ItemFilter) => { filter.vocFrequency = newFilter; onRefresh(); };

    const onFilterValueSearch = async (columnName: string, top: number, skip: number, filter: string) => service.RetrieveAvoVocFacilityInspectionFrequencySearchListAsync(columnName, top, skip, filter);


    return (
        <>
            <OdsEditAvoVocFrequencyDialog show={!!editItem} service={service} frequency={editItem} inspectionFrequencies={AvoVocFacilityInspectionFrequency.inspectionFrequencyTypes} onComplete={onEditItemCompleted} />
            <OdsConfirmDeleteDialog show={!!removeItem} text={"inspection frequencies for " + removeItem?.facility?.name} onComplete={onRemoveItemCompleted} />
            <CvxPage desktop={
                <CvxLayoutCenterColumn>
                    <CvxContentBlock>
                        <OdsTableBanner onAddItem={canEdit ? onAddItem : undefined} onClearFilter={onClearFilter} onRefresh={onRefresh} onExport={onExport} />
                    </CvxContentBlock>
                    <CvxTableContentBlock>
                        <OdsTable heading="AVO/VOC Inspection Frequencies" data={data} isLoading={isLoading} pageIndex={pageIndex} pageSize={pageSize} onPageChanged={onPageChanged}
                            tableHeader={
                                <tr>
                                    {canEdit ? <th></th> : ''}
                                    <th></th>
                                    <OdsIntegerColumnHeader header="Id" name="FacilityId" filter={filter.facilityId} sort={sort} sortDirection={sortDirection}
                                        onSortChanged={onSortChanged} onFilterChanged={onFilterFacilityId} onSearch={onFilterValueSearch} />
                                    <OdsStringColumnHeader header="Facility" name="FacilityName" filter={filter.facilityName} sort={sort} sortDirection={sortDirection}
                                        onSortChanged={onSortChanged} onFilterChanged={onFilterFacilityName} onSearch={onFilterValueSearch} />
                                    <OdsStringColumnHeader header="Route" name="RouteName" filter={filter.routeName} sort={sort} sortDirection={sortDirection}
                                        onSortChanged={onSortChanged} onFilterChanged={onFilterRouteName} onSearch={onFilterValueSearch} />
                                    <OdsItemColumnHeader className="center-column" header="AVO&nbsp;Inspection&nbsp;Frequency" name="AvoInspectionFrequency" filter={filter.avoFrequency} sort={sort} sortDirection={sortDirection}
                                        onSortChanged={onSortChanged} onFilterChanged={onFilterAvoFrequency} onSearch={onFilterValueSearch} />
                                    <OdsItemColumnHeader className="center-column" header="VOC&nbsp;Inspection&nbsp;Frequency" name="VocInspectionFrequency" filter={filter.vocFrequency} sort={sort} sortDirection={sortDirection}
                                        onSortChanged={onSortChanged} onFilterChanged={onFilterVocFrequency} onSearch={onFilterValueSearch} />
                                    {/* <th>History</th> */}
                                    {canEdit ? <th></th> : ''}
                                </tr>
                            }
                            tableRow={(item) =>
                                <OdsSelectableTableRow item={item} selectedItems={selectedItems}>
                                    {canEdit ? <OdsEditItemColumn item={item} onEditItem={onEditItem} /> : <td></td>}
                                    <OdsSelectItemColumn item={item} onSelectItem={onRowSelect} />
                                    <OdsIntegerColumn>{item.id}</OdsIntegerColumn>
                                    <OdsFacilityInspectionRouteColumnPair item={item} personnelService={personnelService} />
                                    <td className="center-column">{item.avoInspectionFrequency}</td>
                                    <td className="center-column">{item.vocInspectionFrequency}</td>
                                    {/* <td className="center-column"><BsCalendar4Week className={Themes.PrimaryDarkColor} /></td> */}
                                    {canEdit ? <OdsRemoveItemColumn item={item} onRemoveItem={onRemoveItem} /> : <td></td>}
                                </OdsSelectableTableRow>
                            } />
                    </CvxTableContentBlock>
                </CvxLayoutCenterColumn>
            } />
        </>
    );
};
